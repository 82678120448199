<template>
  <div class="greybackgroundmatchscreen">
    <div v-if="showCurrentTask">
      <div class="d-flex">
        <div style="display: flex;">
          <button class="backbutton" @click="importGoback"><i class="fas fa-arrow-left"
              style='color:#000000;'></i></button>
        </div>
        <div class="d-flex">
          <div class="matchbox">
            <inline-svg class="matchicon" width="30" height="30" src="media/svg/icons/General/matched_fileicon.svg" />
          </div>
        </div>
        &nbsp;&nbsp; <div style="display: flex;align-items: center;margin-top: 12px;">
          <p class="filename">{{ currentTask.title }}</p>
        </div>
      </div>

      <div class="Dashboard-DataTitleRow">
        <div class="col-sm-5">
          <p class="Dashboard-Map-your-Data">Map your Data</p>
          <p class="Dashboard-Map-your-Text">
            Please map your uploaded data to our pre-defined fields. Once this
            has been completed, click the button to begin the data cleanse.
          </p>
        </div>
        <!-- <b-col> -->
        <div class="col-sm-4">
          <!-- <b-col> -->
          <div class=" text-right">
            <div class="Dashboard-unMap-your-Data">
              <!-- <center> -->
              Unmapped/Ignore Fields :&nbsp;&nbsp;
              <span class="Dashboard-Map-your-Data-Number">{{
                UnMappedCount
                }}<i class="fa fa-info-circle" title="Do you like my fa-info-circle icon?" aria-hidden="true"
                  style="margin-left: 10px;"></i></span>

              <!-- <span>&nbsp;&nbsp; &nbsp;&nbsp; </span>
                    <button id="help"  
                        data-v-step='mapping'
                        @click="startTour(true)">
                        <inline-svg  width="30" height="30" src="media/svg/icons/General/Help-Icon.svg"  />
                    </button>            -->
              <!-- </center> -->
            </div>
          </div>
          <!-- </b-col> -->
          <!-- <b-col><p class="Dashboard-Map-your-Data-Number"><center><br/>{{noUnMapped()}}</center></p></b-col> -->
        </div>
        <!-- </b-col> -->
        <div class="col-sm-3" style="text-align: end;">
          <button type="reset" class="btn btn-primary continuebutton" @click="confirmFields()" ref="kt_save_changes"
            style="">
            Click here to continue
          </button>
        </div>
      </div>

      <!-- <b-row>
        <b-col class="dashboarmapp-left-pad"> -->
      <div class="dummy_map_table_container overflow-auto">
        <div class="dummy_scroll_content my_table">
          <b-table sort-icon :items="fieldData" :fields="matchingFields" header-class="my-header-class" :head-class="''"
            tbody-class="my-table-body" class="field-match-list custom-b-table-class" :tbody-tr-class="addClasses">
            <template #cell(text)="data">
              <div v-if="mappings[data.index] != 0" class="dashboard-data-field-text-holder">
                {{ MapTextLimited(data.item.text) }}
                <!-- {{ data.item.text }} -->
              </div>
              <div v-if="mappings[data.index] == 0" class="dashboard-data-field-unmaptext-holder">
                {{ MapTextLimited(data.item.text) }}
                <!-- {{ data.item.text }} -->
              </div>
            </template>
            <template #cell(matchFlag)="data">
              <div v-if="data.index == 0">
                <div data-v-step="mapping"></div>
              </div>
              <div class="dashboard-data-field-tick-rholder">
                <v-icon small v-if="mappings[data.index] == 0" class="dashboard-data-field-tick-icon-fail"
                  style="font-size: 30px;">mdi-help-circle</v-icon>
                <v-icon small v-if="mappings[data.index] != 0" class="dashboard-data-field-tick-icon-success"
                  style="font-size: 30px;">mdi-check-circle</v-icon>
              </div>
            </template>

            <template #cell(lookupField)="data">
              <div :data-v-step="data.index"></div>
              <div></div>
              <select @change="onChangeSelection(data.index)" v-model="mappings[data.index]" class="
                                                    dashboard-data-field-text-rholder dashboard-showtick-dropdown
                                                  " v-bind:key="'select' + data.index" :ref="'select' + data.index"
                v-if="showSelects">
                <option v-for="(lookupField, i) in lookupFields" :value="i" style="color: black" v-bind:key="i">
                  {{ lookupField }}
                </option>
              </select>
            </template>
            <template #cell(sample1)="data">
              <div class="dashboard-data-field-sample">
                {{ showDataField(data.index, 1) }}
              </div>
            </template>
            <template #cell(sample2)="data">
              <div class="dashboard-data-field-sample">
                {{ showDataField(data.index, 2) }}
              </div>
            </template>
          </b-table>
        </div>
      </div>
      <!-- </b-col>
      </b-row> -->
    </div>

    <template>
      <v-tour name="onboarding-tour" :steps="steps" :options="myOptions">
        <template slot-scope="tour">
          <transition name="fade">
            <v-step v-if="tour.steps[tour.currentStep]" :key="tour.currentStep" :step="tour.steps[tour.currentStep]"
              :previous-step="tour.previousStep" :next-step="tour.nextStep" :stop="tour.stop" :is-first="tour.isFirst"
              :is-last="tour.isLast" :labels="tour.labels" :highlight="tour.highlight">
              <template>
                <!-- v-if="tour.currentStep === 0" -->
                <div slot="actions" class="flex items-center justify-center">
                  <button @click="tour.skip" class="v-step__button-skip">
                    Skip
                  </button>
                  <button v-if="tour.currentStep > 0" @click="tour.previousStep" class="v-step__button-previous">
                    Previous
                  </button>
                  <button v-if="tour.currentStep !== stepsCount - 1" @click="tour.nextStep" class="v-step__button-next">
                    Next
                  </button>
                  <button @click="tour.finish" v-else class="v-step__button-stop">
                    OK
                  </button>
                </div>
              </template>
            </v-step>
          </transition>
        </template>
      </v-tour>
    </template>
  </div>
</template>

<script>


import { mapGetters } from "vuex";
import {
  UPDATE_TASKS,
  UPDATE_TO_PROCESS
} from "@/core/services/store/tasks.module";
import { UPDATE_USER_SETTING } from "@/core/services/store/auth.module";

import { useShepherd } from "vue-shepherd";
import { info } from "console";
require("shepherd.js/dist/css/shepherd.css");
// const tour = useShepherd({
//   useModalOverlay: true,
// });

export default {
  name: "dashboard",
  components: {},
  props: ["helpValue", "refreshValue"],
  watch: {
    helpValue: function () {
      // watch it
      this.startTour(true);
    },
    refreshValue: function () {
      // this.tagclick("");
    },
  },
  data() {
    return {
      myOptions: {
        useKeyboardNavigation: false,
        labels: {
          buttonSkip: "Skip",
          buttonPrevious: "Previous",
          buttonNext: "Next",
          buttonStop: "Finish"
        }
      },
      flag: false,
      jsonData: {},
      tourSteps1: [],
      stepsCount: 100,
      steps: Array(100).fill({}),
      // Note 'isActive' is left out and will not appear in the rendered table
      filter: "",
      UnMappedCount: 0,
      currentTask: null,
      showCurrentTask: false,
      showSelects: true,
      //vue-shephered variabale
      tour: null,
      matchingFields: [
        {
          key: "text",
          label: "Your Fields",
          class: "dashboard-firstcolumn",
          thClass: "text-left",
          tdClass: "align-middle",
        },
        {
          key: "matchFlag",
          label: "",
          class: "dashboard-secondcolumn",
          tdstyle: { width: "100px" },
        },
        {
          key: "lookupField",
          label: "Mapped Fields",
          // tdClass: "dashboard-data-field-tick-right-box  ",
          class: "dashboard-thirdcolumn",
          thClass: "text-left",
          tdClass: "align-middle",
        },
        {
          key: "sample1",
          label: "Sample 1",
          class: "dashboard-data-field-tick-example-data",
          thClass: "text-center",
          tdClass: "align-middle",
        },
        {
          key: "sample2",
          label: "Sample 2",
          class: "dashboard-data-field-tick-example-data2",
          thClass: "text-center",
          tdClass: "align-middle",
        },
        // {
        //   key: "match2Flag",
        //   label: "",
        //   tdClass: "dashboard-lastcolumn"
        // }
      ],
      fields: [
        {
          key: "title",
          label: "File Name",
          sortable: true
        },
        {
          key: "progress_status",
          label: "Status",
          sortable: true
        },
        {
          key: "file_size",
          label: "Size",
          sortable: true
        },
        {
          key: "created_at",
          label: "Date",
          sortable: true
        },
        {
          key: "tags",
          label: "Tags"
        },
        {
          key: "actions",
          label: ""
        }
      ],
      lookupFields: [
        "Unmapped",
        "URN",
        "Title",
        "Initial",
        "Full Name",
        "First Name",
        "Surname",
        "Company Name",
        "Address line 1",
        "Address line 2",
        "Address line 3",
        "Town",
        "County",
        "Postcode",
        "Telephone 1",
        "Telephone 2",
        "Telephone 3",
        "Email Address 1",
        "Email Address 2",
        "Email Address 3"
      ]
    };
  },
  mounted() {

    if (this.tour != null) {
      if (this.tour.steps.length >= 0) {
        this.tour.steps.forEach((step) => {
          this.tour.removeStep(step.id);
          // step.destroy();
        });
      }
    }

    this.tour = useShepherd({ useModalOverlay: true, }),

      this.getData("");
  },
  updated() {
    this.noUnMapped();
    if (this.tour.steps.length == 0) {
      setTimeout(() => {
        this.startTour();
      }, 2000);
    }
  },
  beforeDestroy() {
    clearInterval(this.polling);

    if (this.tour.steps.length >= 0) {
      this.tour.complete();
    }

    // if (tour.steps.length > 0) {
    //   if (typeof tour.stop === "function") { 
    //     tour.stop();
    //   }
    // }
  },
  destroy() {
    // remove all steps
    for (var i = 0; i < 5; i++) {
      if (this.tour.steps.length >= 0) {
        this.tour.steps.forEach((step) => {
          this.tour.removeStep(step.id);
          // step.destroy();
        });
      }
    }
  },
  methods: {
    addClasses(row) {
      if (row.value === this.fieldData.length) {
        return 'last-row'
      } else {
        return 'table-row-height'
      }
    },
    turnOffTour() {
      if (
        this.currentUser.userData.onboarding_flag == "1" ||
        this.currentUser.userData.onboarding_flag == true
      ) {
        var startHelp = document.getElementById("startHelpRef");
        startHelp.click();
      }
    },
    tourComplete() {
      //nothing todo here, just hide the current onboarding when OK button clicked

      var onboardingFlag = this.$store.getters.currentUser.userData.onboarding.substr(0, 1);
      var onboardingStep = this.$store.getters.currentUser.userData.onboarding.substr(2, 5);
      var heatmap = this.$store.getters.currentUser.userData.heatmap;
      var onboarding_flag = this.$store.getters.currentUser.userData.onboarding_flag;
      if (onboarding_flag == 1 && onboardingFlag == "N" && (onboardingStep == 4)) {
        onboardingStep = parseInt(onboardingStep) + 1;

        var onboarding = onboardingFlag + "-" + onboardingStep;

        this.$store.dispatch(
          UPDATE_USER_SETTING,
          { onboarding, heatmap, onboarding_flag },
          this.$store.getters.currentUser.userData.id
        );

        this.$store.commit('setUserSetting', { onboarding: onboarding, heatmap: heatmap, onboarding_flag: onboarding_flag });
      }
    },
    startTour() {

      var onboarding_flag = false;
      if (
        this.currentUser.userData.onboarding_flag == "1" ||
        this.currentUser.userData.onboarding_flag == true
      ) {
        onboarding_flag = true;
      }
      var onboardingStep = this.currentUser.userData.onboarding.substr(2, 5);

      //event fire off
      this.tour.off("cancel");
      this.tour.off("complete");

      // remove all steps
      for (var i = 0; i < 5; i++) {
        if (this.tour.steps.length >= 0) {
          this.tour.steps.forEach((step) => {
            this.tour.removeStep(step.id);
            // step.destroy();
          });
        }
      }

      // Delete any steps in the list from the reload
      this.tour.steps.forEach((step) => {
        step.destroy();
      });

      // organize tour steps
      if (onboardingStep == 4) {
        this.tour.addStep({
          title: "Data Mapping",
          text: `Please use this tool to map your data. We have tried to match all fields automatically, however you can use this tool to adjust or confirm.
                  <br/><br/>
                  Any fields that do not map, please leave them to 'Unmapped' and they will not be processed but will still be on your export.
                  <br/><br/>
                  Any items with a ? require you to confirm the correct mapping field.
                  <br/><br/>
                  Once you are happy, please press the 'Continue' button to confirm and schedule the data cleanse.`,
          attachTo: {
            //element: "[data-v-step='mapping']",
            element: "[data-v-step='0']",
            on: "right",
          },
          buttons: [
            {
              action() {
                return this.cancel();
              },
              classes: "shepherd-button-secondary",
              text: "Skip",
            },
            {
              action() {
                return this.complete();
                // return this.next();
              },
              text: "OK",
            },
          ],
          id: "step-3",
        });
      }

      //event fire on
      this.tour.on("cancel", this.turnOffTour);
      this.tour.on("complete", this.tourComplete);

      if (onboarding_flag === true && this.tour.steps.length > 0) {
        this.tour.start();
      }

    },
    getData(filter) {
      this.$store
        .dispatch(UPDATE_TASKS, {
          filter
        })
        .then(() => {
          this.currentTasks.forEach(record => {
            if (record.id == this.$route.params.id) {
              this.currentTask = record;
              this.populateDropDownData();
              this.showCurrentTask = true;
            }
          });
        });
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      // Then specify how you want your dates to be formatted
      return new Intl.DateTimeFormat("en-GB", { dateStyle: "full" }).format(
        date
      );
    },
    importGoback() {
      this.$router.push({ name: "dashboard" });
    },

    confirmFields() {
      this.showContent = false;

      var options = this.currentTask.options;

      options.name_title = 0;
      options.name_initial = 0;
      options.name_fullname = 0;
      options.name_forename = 0;
      options.name_surname = 0;
      options.organisation_name = 0;
      options.address_line_1 = 0;
      options.address_line_2 = 0;
      options.address_line_3 = 0;
      options.address_town = 0;
      options.address_county = 0;
      options.address_postcode = 0;
      options.telephone_1 = 0;
      options.telephone_2 = 0;
      options.telephone_3 = 0;
      options.email_1 = 0;
      options.email_2 = 0;
      options.email_3 = 0;

      for (var i = this.mappings.length - 1; i >= 0; i--) {
        if (this.mappings[i] > 0) {
          if (this.mappings[i] == 1) {
            options.URN = i + 1;
          }
          if (this.mappings[i] == 2) {
            options.name_title = i + 1;
          }
          if (this.mappings[i] == 3) {
            options.name_initial = i + 1;
          }
          if (this.mappings[i] == 4) {
            options.name_fullname = i + 1;
          }
          if (this.mappings[i] == 5) {
            options.name_forename = i + 1;
          }
          if (this.mappings[i] == 6) {
            options.name_surname = i + 1;
          }
          if (this.mappings[i] == 7) {
            options.organisation_name = i + 1;
          }
          if (this.mappings[i] == 8) {
            options.address_line_1 = i + 1;
          }
          if (this.mappings[i] == 9) {
            options.address_line_2 = i + 1;
          }
          if (this.mappings[i] == 10) {
            options.address_line_3 = i + 1;
          }
          if (this.mappings[i] == 11) {
            options.address_town = i + 1;
          }
          if (this.mappings[i] == 12) {
            options.address_county = i + 1;
          }
          if (this.mappings[i] == 13) {
            options.address_postcode = i + 1;
          }
          if (this.mappings[i] == 14) {
            options.telephone_1 = i + 1;
          }
          if (this.mappings[i] == 15) {
            options.telephone_2 = i + 1;
          }
          if (this.mappings[i] == 16) {
            options.telephone_3 = i + 1;
          }
          if (this.mappings[i] == 17) {
            options.email_1 = i + 1;
          }
          if (this.mappings[i] == 18) {
            options.email_2 = i + 1;
          }
          if (this.mappings[i] == 19) {
            options.email_3 = i + 1;
          }
        }
      }

      this.$store
        .dispatch(UPDATE_TO_PROCESS, {
          id: this.currentTask.id,
          options: options
        })
        .then(() => {
          setTimeout(() => {
            this.$router.push({ name: "dashboard" });
          }, 500);
        });
    },

    populateDropDownData() {
      this.total_rows = this.currentTask.options.total_rows;
      this.header_columns = this.currentTask.options.header_columns;

      var fieldData = [];
      var mappings = [];

      for (var i = 1; i <= this.header_columns; i++) {
        var data = this.currentTask.options.baserecord[
          "f" + ("000" + i).slice(-3)
        ];

        if (data != null) {
          // add to the list
          fieldData.push({ text: data, value: i });
        } else {
          fieldData.push({ text: "Empty Field", value: i });
        }

        var matchField = 0;
        if (this.currentTask.options.URN == i) {
          matchField = 1;
        }
        if (this.currentTask.options.name_title == i) {
          matchField = 2;
        }
        if (this.currentTask.options.name_initial == i) {
          matchField = 3;
        }
        if (this.currentTask.options.name_fullname == i) {
          matchField = 4;
        }
        if (this.currentTask.options.name_forename == i) {
          matchField = 5;
        }
        if (this.currentTask.options.name_surname == i) {
          matchField = 6;
        }
        if (this.currentTask.options.organisation_name == i) {
          matchField = 7;
        }
        if (this.currentTask.options.address_line_1 == i) {
          matchField = 8;
        }
        if (this.currentTask.options.address_line_2 == i) {
          matchField = 9;
        }
        if (this.currentTask.options.address_line_3 == i) {
          matchField = 10;
        }
        if (this.currentTask.options.address_town == i) {
          matchField = 11;
        }
        if (this.currentTask.options.address_county == i) {
          matchField = 12;
        }
        if (this.currentTask.options.address_postcode == i) {
          matchField = 13;
        }
        if (this.currentTask.options.telephone_1 == i) {
          matchField = 14;
        }
        if (this.currentTask.options.telephone_2 == i) {
          matchField = 15;
        }
        if (this.currentTask.options.telephone_3 == i) {
          matchField = 16;
        }
        if (this.currentTask.options.email_1 == i) {
          matchField = 17;
        }
        if (this.currentTask.options.email_2 == i) {
          matchField = 18;
        }
        if (this.currentTask.options.email_3 == i) {
          matchField = 19;
        }

        mappings.push(matchField);
      }

      this.fieldOptions = this.currentTask.options;
      this.fieldData = fieldData;
      this.mappings = mappings;
      this.sampleRecords = this.currentTask.options.samplerecords;

      this.$emit("toggleTodoSidebar");
    },
    showDataField(index, pos) {
      var retStr = "";

      var record = this.sampleRecords[pos];

      if (record != undefined) {
        var fieldNameTemp = "000" + (index + 1);

        var fieldName = fieldNameTemp.slice(
          fieldNameTemp.length - 3,
          fieldNameTemp.length
        );

        retStr = record["f" + fieldName];
      }

      if ( retStr.length > 40 ) {	
        return retStr.substring(0,40) + '***';
      } else {	
        return retStr	;
      }
    },
    noUnMapped() {
      var retVal = 0;

      for (var i = this.mappings.length - 1; i >= 0; i--) {
        if (this.mappings[i] == 0) {
          retVal = retVal + 1;
        }
      }

      this.UnMappedCount = retVal;

      return true;
    },
    onChangeSelection(fIndex) {
      this.showSelects = false;

      var selectedValue = this.mappings[fIndex];

      this.mappings[fIndex] = parseInt(this.$refs["select" + fIndex].value);

      for (var i = this.mappings.length - 1; i >= 0; i--) {
        if (i != fIndex) {
          if (this.mappings[i] == selectedValue) {
            this.mappings[i] = 0;
          }
        }
      }

      this.showSelects = true;

      this.noUnMapped();
    },
    MapTextLimited(field){	
      if ( field.length > 21 ) {	
        return field.substring(0,21) + '***'	
      } else {	
        return field	
      }	
    }
  },
  computed: {
    ...mapGetters(["currentTasks", "currentUser", "isAuthenticated"]),
    getMappings() {
      return this.mappings;
    }
  }
};
</script>

<style>
.field-match-list {
  border-radius: 12px;
  background-color: white !important;
  border-radius: 12px;
  /* padding-bottom: 10px; */
}

.field-match-list thead tr {
  background-color: #EBEBEB;
  padding: 0;
}

.field-match-list tr:last-child th:first-child {
  border-top-left-radius: 10px !important;
}

.field-match-list tr:last-child th:last-child {
  border-top-right-radius: 10px !important;
}

.field-match-list td {
  border-top: 0;
  padding: 0;
  /* padding-bottom: 10px; */
}

.field-match-list thead th {
  height: 60px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #3D415B !important;
  text-align: center;
  /* padding-bottom: 16px; */

}

.field-match-list tbody tr:hover {
  background-color: white;
}


.dashboardTagButton {
  background-color: transparent !important;
  width: 100%;
}

.dashboardTagButton button {
  background-color: transparent !important;
  border-color: transparent !important;
  padding: 0;
  font-weight: 700;
  color: #3f4254;
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  width: 100%;
}

.dashboardactions .dropdown-toggle-no-caret {
  border: 0;
}


.dashboarmapp-left-pad {
  margin-left: 60px;
}

.Dashboard-Go-Back {
  cursor: pointer;
  opacity: 0.8;
  font-size: 13px;
  line-height: 70px;
  text-align: left;
  color: #3f6f86;
}

.Dashboard-Readr-Campaign-Dataxls {
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  color: #3f6f86;

}

.Dashboard-Map-your-Data {
  width: 155px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  /* identical to box height */

  color: #3D415B;
}

.Dashboard-unMap-your-Data {
  /* width: 150px; */

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 30px;
}

.Dashboard-Map-your-Data-Number {
  font-size: 17px;
  font-weight: 600;
  color: #ea9430;
}

.Dashboard-Map-your-Text {
  /* width: 690px; */


  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;

  color: #3D415B;
}

.dashboard-data-field-tick-holder {

  width: 100%;
  min-width: 100px;
  margin-top: 20px;
  padding-top: 20px;
  text-align: center;
  background-image: linear-gradient(#d9d9d9, #d9d9d9);
  background-size: 100% 1px;
  background-repeat: no-repeat;
  background-position: center center;
}

.dashboard-secondcolumn {
  width: 100px;
}

.dashboard-data-field-tick-rholder {

  width: 100px;
  margin-top: 25px;
  padding-top: 20px;
  text-align: center;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#d9d9d9), to(#d9d9d9));
  background-image: linear-gradient(#d9d9d9, #d9d9d9);
  background-size: 100% 1px;
  background-repeat: no-repeat;
  background-position: center center;
  margin-left: 0px;
}

.dashboard-data-field-tick-icon-success {
  background-color: white;
  color: #33b985 !important;
  margin-top: -35px;
}

.dashboard-data-field-tick-icon-fail {
  background-color: white;
  color: #ea9430 !important;
  font-size: 26px;
  margin-top: -35px;
}

.dashboard-data-field-text-holder {
  text-align: left;
  height: 42px;
  width: 265px;
  margin-top: 20px;

  padding: 7px 19px 19px 33px;
  border-radius: 10px;
  border: solid 1px #d9d9d9;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #3D415B;
}

.dashboard-data-field-unmaptext-holder {
  text-align: left;
  height: 42px;
  width: 265px;
  margin-top: 20px;
  padding: 7px 19px 19px 33px;
  border-radius: 10px;
  border: solid 1px #ea9430;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #3D415B;
}

.dashboard-data-field-text-rholder {
  height: 50px;
  width: 265px;
  padding: 7px 19px 19px 33px;
  border-radius: 10px;
  margin-top: 16px;
  /* border: solid 1px #d9d9d9; */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #3D415B;
  background-color: #F8F8F8;
  /* border: none; */
}

.dashboard-showtick-dropdown {
  -webkit-appearance: auto;
}

.dashboard-data-field-sample {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  /* identical to box height */
  text-align: center;
  padding-top: 31px;
  /* padding-left: 72px; */
  color: #3D415B;
  margin-top: 0px;
  word-wrap: break-word;

}

.dashboard-data-field-tick-example-data {
  height: 42px;
  width: 500px;
  margin: 20px 0.5px 12.5px 50px;
  /* padding: 15px 169px 19px 33px; */
  /* border-radius: 10px;
  border-right: solid 1px #d9d9d9; */
  /* border-right: solid 1px #d9d9d9;
  border-bottom: solid 1px #d9d9d9; */
  font-size: 18px;
  color: black;
}

.dashboard-data-field-tick-example-data2 {
  height: 42px;
  width: 500px;
  margin: 20px 0.5px 12.5px 50px;
  /* padding: 15px 169px 19px 33px; */
  /* border-radius: 10px; */

  font-size: 18px;
  color: black;
}

/* .dashboard-data-field-tick-right-box {
  margin-left: 0;
} */
/* .dashboard-thirdcolumn{
width: 295px;
} */
/* .dashboardmatch {
  background-color: #f5f9fd;
}

.dashboardmatch .Dashboard-HeaderRow {
  background-color: white;
  margin-left: -25px;
  margin-top: -25px;
  padding-top: 25px;
} */

.Dashboard-DataTitleRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
}

.dashboard-firstcolumn {
  padding-left: 30px !important;
  width: 265px;
  padding-bottom: 16px;
}

/* .dashboard-lastcolumn {
  padding-right: 30px !important;
}
 */

.filename {
  /* width: 600px; */
  /* height: 30px; */
  /* flex-wrap: wrap; */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  /* margin-top: 19px;
  margin-left: 15px; */
  /* identical to box height */

  color: #3D415B;
}

.matchbox {
  width: 64px;
  border: 2px dashed rgb(149, 186, 242);
  margin-left: 10px;
  border-radius: 10px;
  display: flex;
  align-items: center;
}

.matchicon {
  margin-left: 18px;
}

/* .field-match-list th, .field-match-list td {
  padding-top: 0px;
} */
.table-row-height {
  height: 50px;
}

.last-row {
  height: 50px;
  /* padding-bottom: 60px; */
}

.my-table-body::after {
  content: "";
  display: block;
  height: 20px;
}

.map_table_container {
  /* position: relative; */
  /* overflow: hidden; */
  height: 440px;
  overflow-y: auto;
  display: flex;
}

.custom-b-table-class th {
  position: sticky !important;
  top: 0;
}
</style>



<style scoped>
.table-body {
  scrollbar-width: thin;
  /* or none */
}

.b-table-sticky-header {
  overflow-y: auto;
  max-height: 410px;
}

.scroll_content {
  width: 100%;
  height: 100%;
}

/* Target the scrollbar */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #CCD2E3;
}

/* Handle */
::-webkit-scrollbar-thumb {
  /* background: #888; */
  background: #606060;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.continuebutton {
  width: 237px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 26px;
  height: 60px;
  border-radius: 15px;
}

.my-header-class th {
  padding-left: 50px;
}

.my_table {
  margin-bottom: 20px !important;
  padding-bottom: 20px !important;
}
</style>